module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Francesco Rampazzo","short_name":"Francesco Rampazzo","start_url":"/","background_color":"#d97706","theme_color":"#d97706","display":"standalone","icon":"src/images/Francesco-Rampazzo.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e996fabbb1746e68ecfdc0646f93a87"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"pages":"/Users/pietro/GitHub/chicco/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":890,"linkImagesToOriginal":false,"quality":80,"wrapperStyle":"margin-top:25px;margin-bottom:25px;"}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/pietro/GitHub/chicco"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
